<template>
  <waike title="忘记密码" id="forget">
    <template #form>
      <router-link to="/">返回登录</router-link>
      <div class="login-from">
        <el-form
          :model="ruleForm4"
          status-icon
          :rules="rules4"
          ref="ruleForm4"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="邮箱" prop="uemail">
            <el-input
              type="email"
              auto-complete="off"
              placeholder="请输入邮箱"
              v-model="ruleForm4.uemail"
            ></el-input>
          </el-form-item>

          <el-form-item label="用户类型" prop="type">
            <el-select v-model="ruleForm4.type" style="width: 100%">
              <el-option label="请选择用户类型" value=""></el-option>
              <el-option
                v-for="item in ruleForm4.identity"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="新密码" prop="upwd">
            <el-input
              type="password"
              auto-complete="off"
              placeholder="请输入密码"
              v-model="ruleForm4.upwd"
            ></el-input>
          </el-form-item>

          <el-form-item label="确认密码" prop="checkupwd">
            <el-input
              type="password"
              auto-complete="off"
              placeholder="请再次输入密码"
              v-model="ruleForm4.checkupwd"
            ></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="uyzm">
            <el-input
              type="text"
              auto-complete="off"
              placeholder="请输入验证码"
              v-model="ruleForm4.uyzm"
              ><el-button :disabled="disabled" slot="append" @click="send"
                >发送验证码</el-button
              ></el-input
            ></el-form-item
          >

          <div class="button">
            <el-button type="primary" @click="submitForm('ruleForm4')"
              >提交</el-button
            >
            <el-button type="primary" @click="resetForm('ruleForm4')"
              >重置</el-button
            >
          </div>
        </el-form>
      </div>
    </template>
  </waike>
</template>
<script>
import waike from "./slot/waike.vue";
export default {
  components: { waike },
  data() {
    var validateUemail = (rule, value, callback) => {
      //发起请求验证输入的邮箱是否存在
      this.$http
        .post("/api/global/checkemail", `email=${value}`)
        .then((result) => {
          this.uemailStatus = result.data.status;
          if (value === "") {
            this.disabled = true; //防止用户反复操作,如果不设置，用户本来输入正确了，此时按钮解除禁用，
            //然后突然删除又变错误了，此时要禁用按钮
            callback(new Error("请输入邮箱"));
          } else if (this.uemailStatus != 200) {
            this.disabled = true;
            callback(new Error("该邮箱不存在"));
          } else if (
            !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
          ) {
            this.disabled = true;
            callback(new Error("请输入正确的邮箱"));
          } else {
            this.disabled = false; //输入正确解除禁用
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    var validateType = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择用户类型"));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 8 || /^\d+$/.test(value)) {
        callback(new Error("密码不能为纯数字,且不能小于8位"));
      } else {
        callback();
      }
    };

    var validateCheckupwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm4.upwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validateYzm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value != this.ruleForm4.code) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    return {
      disabled: true,
      uemailStatus: "",
      ruleForm4: {
        uemail: "",
        identity: ["超级管理员", "教师", "学生"],
        type: "",
        upwd: "",
        checkupwd: "",
        uyzm: "",
        code: "",
      },
      rules4: {
        uemail: [{ validator: validateUemail, trigger: "blur" }],
        type: [{ validator: validateType, trigger: "change" }],
        upwd: [{ validator: validatePass, trigger: "blur" }],
        checkupwd: [{ validator: validateCheckupwd, trigger: "blur" }],
        uyzm: [{ validator: validateYzm, trigger: "blur" }],
      },
    };
  },
  methods: {
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var forgetUrl,user;
            if(this.ruleForm4.type=='超级管理员'){forgetUrl='/api/super/forget';user='super'}
            else if(this.ruleForm4.type=='教师'){forgetUrl='/api/teacher/forget';user='teacher'}
            else if(this.ruleForm4.type=='学生'){forgetUrl='/api/student/forget';user='student'}
          let obj = {
            password: this.ruleForm4.upwd,
            email: this.ruleForm4.uemail,
            verify:this.ruleForm4.code
          };
          this.$http.post(forgetUrl,obj).then(res=>{
                    if(res.data.status==200){
                        this.$message({message:'密码修改成功',type:'success'})
                        this.$router.push('/login')
                    }else{
                        this.$message({message:res.data.msg,type:'error'})
                    }
                })
        } else {
          return false;
        }
      });
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //发送验证码axios,多请求操作
    sendaxios() {
      this.$http
        .get("/api/global/email", {
          params: {
            email: this.ruleForm4.uemail,
          },
        })
        .then((res) => {
          this.ruleForm4.code = res.data.yzm;
          //   console.log(res.data.yzm);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    send(e) {
      this.sendaxios();
      let time = 60; //定义60秒的倒计时
      let that = this; //因为this在函数中指向window，所以定义that接收
      function subs() {
        time--;
        e.target.innerHTML = `请${time}秒后再试`;
        if (time === 0) {
          clearInterval(mytime);
          e.target.innerHTML = `发送验证码`;
          that.disabled = false; //按键可用
        } else {
          that.disabled = true; //按键不可用
        }
      }
      //设置一个定时，一秒执行一次
      let mytime = setInterval(function () {
        subs();
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
#forget {
  .login-content {
    height: 550px;
  }
}
</style>